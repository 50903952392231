<template>
  <div>
    <c-card title="문제 풀이" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable"
            :url="insertUrl"
            :isSubmit="isSave"
            :param="saveData"
            mappingType="POST"
            label="정답제출"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12 divquizsolve" v-for="(item, idx) in questionItems" :key="idx">
          <div class="lawspan">{{item.questionContent2}} <span style="color:red !important;">{{ item.questionResult }}</span></div>
          <q-checkbox
            v-for="(answer, _idx) in item.answers" :key="_idx"
            class="customqcbox"
            dense
            color="orange-custom"
            true-value="Y"
            :name="answer.eduAnswerId"
            false-value="N"
            :label="answer.answerContent2"
            v-model="answer.val"
            @input="checkAnswer(idx, answer.eduAnswerId)"
          />
        </div>
        <br/><br/>
        <br/><br/>
        <br/><br/>
      </template>
    </c-card>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'solving-quiz',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        eduQuestionMstId: '',
      }),
    },
  },
  data() {
    return {
      totalScore: 0,
      questionItems: [],
      listtUrl: '',
      insertUrl: '',
      isSave: false,
      editable: true,
      eduQuestionPassScore: 0,
      checks: [],
      saveData: {
        eduEducationId: '',
        userId: '',
        score: 0,
      }
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {      
      this.listtUrl = selectConfig.sop.edu.quiz.sets.get.url;
      this.insertUrl = transactionConfig.sop.edu.quiz.result.insert.url;
      this.getQuestions();
    },
    getQuestions() {
      if (this.popupParam.eduQuestionMstId) {
        this.$http.url = this.listtUrl;
        this.$http.param = {eduQuestionMstId: this.popupParam.eduQuestionMstId, eduEducationId: this.popupParam.eduEducationId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data.questions && _result.data.questions.length > 0) {
            this.questionItems = _result.data.questions
            this.eduQuestionPassScore = parseInt(_result.data.eduQuestionPassScore);
          } else {
            this.questionItems = []
          }
        },);
      }
    },
    checkAnswer(_idx, _aid) {
      this.$_.forEach(this.questionItems[_idx].answers, _answer => {
        if (_answer.eduAnswerId !== _aid) {
          _answer.val = 'N';
        }
      })
    },
    saveInfo() {
      let isCheck = false;
      this.$_.forEach(this.questionItems, _question => {
        let _anscnt = 0;
        this.$_.forEach(_question.answers, _answers => {
          if (_answers.val === 'Y') {
            _anscnt++;
          }
        })
        if (_anscnt < 1) {
          isCheck = true;
          return;
        }
      })
      if (isCheck) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '정답을 체크 하지 않은 문제가 있습니다.', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '정답을 제출하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.totalScore = 0;
            this.$_.forEach(this.questionItems, _question => {
              _question.questionResult = ' (X)';
              this.$_.forEach(_question.answers, _answers => {
                if (_answers.answerFlag == 'Y' && _answers.val == 'Y') {
                  this.totalScore += parseInt(_question.questionPoint);
                  _question.questionResult = ' (O)';
                }
              })
            })
            
            if (this.totalScore >= this.eduQuestionPassScore) {
              this.saveData.eduEducationId = this.popupParam.eduEducationId;
              this.saveData.userId = this.$store.getters.user.userId;
              this.saveData.score = this.totalScore;
              this.isSave = !this.isSave;
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message: '문제풀이 통과 기준점수이상의 점수를 획득해야 합니다.\r\n통과 기준점수 : ' + this.eduQuestionPassScore + ' 점\r\n문제풀이 획득점수 : ' + this.totalScore + ' 점\r\n문제를 다시 풀어 주시기 바랍니다.', // 선택된 항목이 없습니다.
                type: 'warning', // success / info / warning / error
              });

              this.$_.forEach(this.questionItems, _question => {
                this.$_.forEach(_question.answers, _answers => {
                  _answers.val = 'N'
                })
              })
            }
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('CONFIRM', {
        title: '문제풀이 통과',
        message: '문제풀이 통과 기준점수이상의 점수를 획득하셨습니다.\r\n통과 기준점수 : ' + this.eduQuestionPassScore + ' 점\r\n문제풀이 획득점수 : ' + this.totalScore + ' 점\r\n문제풀이 완료 후 교육이수 처리를 하시겠습니까?', // 선택된 항목이 없습니다.
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$emit('closePopup','SUCCESS');
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
<style type="scss">
.divquizsolve > .q-checkbox {
  display: flex !important;
  padding-top: 6px;
  padding-left: 12px;
}
.divquizsolve > .q-checkbox:last-child {
  padding-bottom: 12px;
}
.divquizsolve > .q-checkbox__label {
  font-size: 1.15em !important;
}
.divquizsolve > .lawspan {
  font-size: 1.2em;
  color: blue;
}
</style>