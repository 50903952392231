var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "문제 풀이" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: {
                      url: _vm.insertUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.saveData,
                      mappingType: "POST",
                      label: "정답제출",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveInfo,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "card-detail" },
            [
              _vm._l(_vm.questionItems, function (item, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "col-12 divquizsolve" },
                  [
                    _c("div", { staticClass: "lawspan" }, [
                      _vm._v(_vm._s(item.questionContent2) + " "),
                      _c("span", { staticStyle: { color: "red !important" } }, [
                        _vm._v(_vm._s(item.questionResult)),
                      ]),
                    ]),
                    _vm._l(item.answers, function (answer, _idx) {
                      return _c("q-checkbox", {
                        key: _idx,
                        staticClass: "customqcbox",
                        attrs: {
                          dense: "",
                          color: "orange-custom",
                          "true-value": "Y",
                          name: answer.eduAnswerId,
                          "false-value": "N",
                          label: answer.answerContent2,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.checkAnswer(idx, answer.eduAnswerId)
                          },
                        },
                        model: {
                          value: answer.val,
                          callback: function ($$v) {
                            _vm.$set(answer, "val", $$v)
                          },
                          expression: "answer.val",
                        },
                      })
                    }),
                  ],
                  2
                )
              }),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
              _c("br"),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }